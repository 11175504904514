import React from 'react';
import { Helmet } from 'react-helmet';

const googleTagManagerCode = 'GTM-KMG5CNVV';

declare global {
  interface Window {
    dataLayer?: any[];
  }
}

export const TrackingProvider: React.FC = ({ children }) => {
  const [isInitialized, setInitialized] = React.useState(
    typeof window !== 'undefined' && typeof window?.dataLayer !== 'undefined'
  );

  const initTracking = React.useCallback(() => {
    window.dataLayer = window.dataLayer || [];

    window.dataLayer.push({
      'gtm.start': new Date().getTime(),
      event: 'gtm.js',
    });
    setInitialized(true);
  }, [setInitialized]);

  React.useEffect(() => {
    if (!isInitialized) initTracking();
  }, [initTracking, isInitialized]);

  return (
    <React.Fragment>
      {children}
      {isInitialized && (
        <Helmet>
          {/*<!-- Start Google Tag Manger -->*/}
          <script
            async
            src={`https://www.googletagmanager.com/gtm.js?id=${googleTagManagerCode}`}
          ></script>
          {/*<!--  End Google Tag Manager -->*/}
        </Helmet>
      )}
    </React.Fragment>
  );
};
